<template>
  <div class="widgetContainer cardManagement bg-white">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <p class="title">
          {{ $t('info_ATM') }}
        </p>
      </div>
    </div>
    
    <div class="widgetContainer__body">
      <div class="cardInfo">
        <div class="cardInfo__card">
          <div class="card-front">
            <Card
              :card="getSelectedCard"
              :show-logo="false"
              :key="getSelectedCard.id"
              :show-secret-data="false"
              :show-activation-switch="true" />
          </div>
        </div>
      </div>

      <div class="listWithActionsv2">
        <ul>
          <li class="cursor-auto" @click="atmPin">
            <div class="details">
              <p class="label">
                {{ $t('set_ATM_Pin') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-arrow-right" />
            </div>
          </li>
          <li class="cursor-auto" @click="findATM" v-if="getSelectedCard.cardType === 'physical'">
            <div class="details">
              <p class="label">
                {{ $t('find_ATM') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-arrow-right" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Card from '../components/Card';

export default {
  name: 'AtmInfo',
  computed: {
    ...mapGetters('card', ['getSelectedCard']),
  },
  components: {
    Card
  },
  methods: {
    atmPin() {
      if(this.getSelectedCard.cardStatus === 'inactive') {
        this.$notify({
          title: 'Error',
          message: this.$t('set_pin_freezeCard_error'),
          type: 'error'
        })
      } else {
        this.drawerPush('SetATMPin');
      }
      this.drawerPush('SetATMPin');
    },
    findATM() {
      this.drawerPush('findATM');
    }
  }
}
</script>